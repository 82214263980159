import MemberModel from '@/views/admin/member/model/MemberModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { groupEncryptWithRSA } from '@lemontree-ai/lemontree-admin-common-front/utils/RsaUtils';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import { PUT_S3_REQUEST } from '@/services/httpS3';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class MemberDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.registerValid = {
      name:{
        value: 'name',
        valueType:'koreanEngNumber',
        isValid:false,
        validAlertDesc: '한글,영문,숫자로만 입력해주세요',
      },
      email:{
        value: 'email',
        valueType:'email',
        isValid:false,
        validAlertDesc: 'E-mail 양식을 확인해주세요',
      },
      password:{
        value: 'password',
        valueType:'password',
        isValid:false,
        validAlertDesc: '대문자, 소문자, 특수문자, 숫자를 조합하여 8자이상 입력해주세요',
      },
      repeatPassword:{
        value: 'repeatPassword',
        valueType:'password_confirm',
        referenceValue:'password',
        isValid:false,
        validAlertDesc: '대문자, 소문자, 특수문자, 숫자를 조합하여 8자이상 입력해주세요',
        referenceVaildAlertDesc: '입력하신 변경할 비밀번호와 동일하게 입력해주세요',
      },
    }
    this.modifyValid = {
      role:{
        value: 'role',
        valueType:'',
        isValid:false,
        validAlertDesc: '권한을 입력해주세요',
      },
      name:{
        value: 'name',
        valueType:'koreanEngNumber',
        isValid:false,
        validAlertDesc: '한글,영문,숫자로만 입력해주세요',
      },
    }
  }

  init(id){
    this.model = new MemberModel();
    if(id){
      this.getMemberDetail(id)
    }
  }

  registerValidatedResult(){
    return pageValidated(this.registerValid, this.model)
  }
  modifyValidatedResult(){
    return pageValidated(this.modifyValid, this.model)
  }

  onClickLock(){
    this.postLock()
  }
  onClickUnlock(){
    this.postUnlock()
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteMemberDetail();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `직원을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // onClickRegisterCompelete(){
  //   this.setPassword();
  // }
  setPassword(){
    const rsaData = {
      password : this.model.password,
      repeatPassword : this.model.repeatPassword,
    }
    groupEncryptWithRSA(rsaData).then((result) => {
      this.model.rsaPasswordData = result;
      this.postMemberDetail();
    });
  }
  // onClickModifyCompelete(){
  //   this.putMemberDetail();
  // }
  onClickCompelete(){
    const callBack = () => {
      if(this.isModify){
        this.putMemberDetail();
      }else{
        this.setPassword();
      }
    }
    if(this.model.imageList.length > 0 && this.model.imageList[0].file){
      this.putS3Upload(this.model.imageList[0], callBack);
    }else{
      callBack();
    }
  }
  // 잠금
  postLock(){
    const path = apiPath.ADMIN_LOCK.format(this.model.id);
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '로그인이 잠금되었습니다');
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 잠금해제
  postUnlock(){
    const path = apiPath.ADMIN_UNLOCK.format(this.model.id);
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '로그인이 잠금해제되었습니다');
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 상세
  getMemberDetail(id){
    const path = `${apiPath.ADMIN_DETAIL}/${id}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 등록
  postMemberDetail(){
    const path = `${apiPath.ADMIN_REGISTER}`;
    const data = this.model.getRegisterData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '직원이 등록되었습니다');
      router.push({ name: 'ADMIN_MEMBER_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 수정
  putMemberDetail(){
    const path = `${apiPath.ADMIN_REGISTER}/${this.model.id}`;
    const data = this.model.getModifyData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '직원정보가 수정되었습니다');
      router.push({ name: 'ADMIN_MEMBER_DETAIL' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 삭제
  deleteMemberDetail(){
    const path = `${apiPath.ADMIN_DELETE}/${this.model.id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', '직원이 삭제되었습니다');
      router.push({ name: 'ADMIN_MEMBER_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  // image
  putS3Upload(fileData, callBack){
    const path = fileData.presignedUrl;
    const data = fileData.file;
    const config = { headers:{ 'Content-Type' : fileData.file.type } };
    PUT_S3_REQUEST(path, data, config).then((success) => {
      this.model.imageList[0].src = fileData.uploadedSrc;
      callBack();
    }, (failed) => {
      this.$store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}